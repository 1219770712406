import React,{useState} from 'react'
import cardButtonStyles from '../../styles/_components/_buttons/_card_button.module.scss'
import House from '../svg/help/house'
import Building from '../svg/help/building'
import Bussiness from '../svg/help/bussiness'
import Flame from '../svg/help/flame'
import Thunder from '../svg/help/thunder'
import Sun from '../svg/help/sun'


function CardButton({image,bodyTitle,bodyDesc}) {
    // eslint-disable-next-line
    const [icon, setIcon] = useState(image)

    function getIcon(icon) {
        if(icon === 'House'){
            return <House/>
        }
        if(icon === 'Building'){
            return <Building/>
        }
        if(icon === 'Bussiness'){
            return <Bussiness/>
        }
        if(icon === 'Flame'){
            return <Flame/>
        }
        if(icon === 'Thunder'){
            return <Thunder/>
        }
        if(icon === 'Sun'){
            return <Sun/>
        }
    }

    return (
        <div className={cardButtonStyles.card__container}>
            <div className={cardButtonStyles.card__container_bgwhite}></div>
            
            <div className={cardButtonStyles.card__image}>
                {getIcon(icon)}
            </div>
            <div className={`card__body ${cardButtonStyles.card__body}`}>
                <div className={cardButtonStyles.card__title}>
                    <h1>{bodyTitle}</h1>
                </div>
                <div className={cardButtonStyles.card__description}>
                    <p>{bodyDesc}</p>
                </div>
            </div>
        </div>
    )
}

export default CardButton
