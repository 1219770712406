import React from "react"

import Help from "../components/help/help_container"
import Head from "../components/head"

const Index = () => {
  let metas = {
    title: "Calorex ",
    description: "Calorex ",
    image: "",
  }

  return (
    <>
      <Head
        title={metas.title}
        pathname={`/`}
        description={metas.description}
        image={metas.image}
      />
      <Help />
    </>
  )
}

export default Index
