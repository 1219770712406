export const useModelsMatrix = () => {
  const matrix = {
    'gas': {
      'services': {
        '1': {
          'persons': {
            '1': ['mg-10', 'maximus-advance-g-10', 'maximus-advance-wifi-g-10', 'fortis-g10', 'poderus-06', 'poderus-advance-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['mg-15', 'maximus-advance-g-15', 'maximus-advance-wifi-g-15', 'fortis-g10', 'poderus-09', 'poderus-advance-09', 'fortis-paso-09', 'plenus-07', 'fortis-s-09'],
            '3': ['plenus-07'],
            '4': ['plenus-07'],
            '5': ['plenus-07'],
            '6': ['plenus-07']
          }

        },
        '2': {
          'persons': {
            '1': ['mg-10', 'maximus-advance-g-10', 'maximus-advance-wifi-g-10', 'fortis-g15', 'poderus-06', 'poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['mg-20', 'maximus-advance-g-20', 'maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11', 'poderus-advance-11', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
            '3': ['plenus-13', 'plenus-advance-14'],
            '4': ['plenus-13', 'plenus-advance-14'],
            '5': ['plenus-13', 'plenus-advance-14'],
            '6': ['plenus-13', 'plenus-advance-14']
          }

        },
        '3': {
          'persons': {
            '1': ['mg-10', 'maximus-advance-g-10', 'maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06', 'poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['mg-20', 'maximus-advance-g-20', 'maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11', 'poderus-advance-11', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
            '3': ['mg-30', 'maximus-advance-g-30', 'maximus-advance-wifi-g-30', 'poderus-15', 'plenus-advance-16', 'fortis-g30'],
            '4': ['plenus-advance-16'],
            '5': ['plenus-advance-16'],
            '6': ['plenus-advance-16']
          }
        },
        '4': {
          'persons': {
            '1': ['mg-10', 'maximus-advance-g-10', 'maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06', 'poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['mg-20', 'maximus-advance-g-20', 'maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11', 'poderus-advance-11', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
            '3': ['mg-30', 'maximus-advance-g-30', 'maximus-advance-wifi-g-30', 'fortis-g30', 'poderus-15', 'plenus-advance-16'],
            '4': ['mg-40', 'maximus-advance-g-40', 'fortis-g40', 'poderus-20', 'plenus-advance-24'],
            '5': ['plenus-advance-24'],
            '6': ['plenus-advance-24']
          }
        },
        '5': {
          'persons': {
            '1': ['fortis-g20', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['fortis-g20', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
            '3': ['fortis-g30', 'plenus-advance-16'],
            '4': ['fortis-g40', 'plenus-advance-24'],
            '5': ['plenus-advance-28'],
            '6': ['plenus-advance-28']
          }
        },
        '6': {
          'persons': {
            '1': ['mg-10', 'maximus-advance-g-10', 'maximus-advance-wifi-g-10', 'fortis-g20', 'poderus-06', 'poderus-advance-06', 'fortis-paso-06', 'plenus-07', 'fortis-06', 'fortis-s-06'],
            '2': ['mg-20', 'maximus-advance-g-20', 'maximus-advance-wifi-g-20', 'fortis-g20', 'poderus-11', 'poderus-advance-11', 'fortis-paso-11', 'plenus-13', 'plenus-advance-14', 'fortis-s-11'],
            '3': ['mg-30', 'maximus-advance-g-30', 'maximus-advance-wifi-g-30', 'fortis-g30', 'poderus-15', 'plenus-advance-16'],
            '4': ['mg-40', 'maximus-advance-g-40', 'poderus-20', 'plenus-advance-24', 'fortis-g40'],
            '5': ['mg-60', 'maximus-advance-g-60', 'cd-kt-180-s'],
            '6': ['mg-60', 'maximus-advance-g-60', 'cd-kt-180-s']
          }
        }
      }


    },
    'electric': {
      'services': {
        '1': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-15', 'pro-50-l', 'levittas-45', 'fortis-e15', 'eleva-9'],
            '3': ['e-20', 'levittas-65', 'eleva-9'],
            '4': ['e-30', 'eleva-9'],
            '5': ['e-40', 'eleva-9'],
            '6': ['e-40', 'eleva-9'],
          }
        },
        '2': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
            '3': ['e-30', 'levittas-65', 'eleva-pro-15', ],
            '4': ['e-40', 'eleva-pro-15'],
            '5': ['e-60', 'eleva-pro-15'],
            '6': ['e-60', 'eleva-pro-15'],
          }
        },
        '3': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
            '3': ['e-30', 'fortis-e30'],
            '4': ['e-40'],
            '5': ['e-60'],
            '6': ['e-60'],
          }
        },
        '4': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
            '3': ['e-30', 'fortis-e30'],
            '4': ['e-40'],
            '5': ['e-60'],
            '6': ['e-60'],
          }
        },
        '5': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
            '3': ['e-30', 'fortis-e30'],
            '4': ['e-40'],
            '5': ['e-60'],
            '6': ['e-60'],
          }
        },
        '6': {
          'persons': {
            '1': ['e-10', 'pro-40-l', 'levittas-45', 'fortis-e10', 'eleva-9'],
            '2': ['e-20', 'levittas-65', 'fortis-e20', 'eleva-pro-15'],
            '3': ['e-30', 'fortis-e30'],
            '4': ['e-40'],
            '5': ['e-60'],
            '6': ['e-60'],
          }
        }

      }
    },
    'sun': {
      'services': {
        '1': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        },
        '2': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        },
        '3': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        },
        '4': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        },
        '5': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        },
        '6': {
          'persons': {
            '1': ['calorex-solar-sl-150'],
            '2': ['calorex-solar-sl-150'],
            '3': ['calorex-solar-sl-150'],
            '4': ['calorex-solar-sl-240'],
            '5': ['calorex-solar-sl-240'],
            '6': ['calorex-solar-sl-240'],
          }
        }
      }
    }
  };
  return matrix
}