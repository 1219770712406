import React,{useState, useEffect} from 'react'
import paginatorStyles from '../../styles/pages/help/_components/_paginator.module.scss'

function PaginatorNumber( props ) {
    const [active, setActive] = useState(props.active)
    const [total, setTotal] = useState(props.total)

    useEffect(() => {
        setActive(props.active)
    }, [props.active])
    useEffect(() => {
        // console.log('cambio el total ', props.total);
        setTotal(props.total)
    }, [props.total])


    function getBullets(total) {
        // for(let index=1; index<=total; index++){
        //     bulletsArray.push(
        //     <li key={index} className={`${paginatorStyles.pagination__bullet} ${active == index ? paginatorStyles.active__bullet : null} 
        //     ${active > index ? paginatorStyles.select__bullet : null} `} ></li>)
        // }
        return (
            <>
                <span>0{active} / 0{total}</span>
            </>
        );
    }

    return (
        <p className={paginatorStyles.paginator__container}>
            {getBullets(total)} 
        </p>
    )
}

export default PaginatorNumber
