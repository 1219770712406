import { useLiverpoolLinks } from "../../../hooks/use_liverpool_links"

export const DEALER_NAME = {
  liverpool: "liverpool",
}

export class LinksManager {
  links = {}
  dealer = DEALER_NAME.liverpool

  constructor() {
    if (typeof window === "undefined") return null
    if (!LinksManager.instance) {
      if (window.location.search) {
        const query = new URLSearchParams(window.location.search)
        const theDealer = query.get("distribuidor")
        this.dealer = DEALER_NAME[theDealer] ? theDealer : DEALER_NAME.liverpool

        console.log("Query", query, "dealer", this.dealer)
      }
      switch (this.dealer) {
        case DEALER_NAME.liverpool:
          this.links = useLiverpoolLinks()
          break
        default:
          this.links = useLiverpoolLinks()
      }
      LinksManager.instance = this
    }
    return LinksManager.instance
  }
}

const instance = new LinksManager()
Object.freeze(instance)

export default instance
