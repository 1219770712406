export const useLiverpoolLinks = () => {
  const links = {
    "g-10": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-a-gas-lp-calorex-1-servicio-blanco/1052726430",
    },
    "g-15": {},
    "g-20": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-a-gas-natural-calorex-2-servicios-blanco/1052726481",
    },
    "g-30": {},
    "g-40": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-a-gas-lp-calorex-4-servicios-blanco/1049090940",
    },
    "g-60": {},

    "g-10-sin-piloto": {
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-1-servicio-blanco-g10-sin-piloto-gas-natural/1068062493",
    },
    "g-15-sin-piloto": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-1-1/2-servicio-blanco-g-15-sin-piloto-gas-lp/1068062507",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-62-litros-blanco-g-15-sin-piloto-gas-natural/1068062515",
    },
    "g-20-sin-piloto": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-72-litros-blanco-g20-sin-piloto-gas-lp/1068062523",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-72-litros-blanco-g20-sin-piloto-gas-natural/1068062531",
    },
    "g-30-sin-piloto": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-103-litros-blanco-g30-sin-piloto-wifi-gas-lp/1068062469",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-103-litros-g30-sin-piloto-gas-natural/1068062558",
    },
    "g-40-sin-piloto": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-132-litros-blanco-g40-sin-piloto-gas-lp/1068062566?typeahead=yes",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-132-litros-g40-sin-piloto-gas-natural/1068062574?typeahead=yes",
    },
    "g-60-sin-piloto": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-6-servicios-blanco-g-60-sin-piloto-gas-lp/1068062582",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-6-servicios-blanco-g60-sin-piloto-gas-natural/1068062591",
    },

    "g-10-sin-piloto-wifi": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-1-servicio-blanco-g10-s/piloto-wifi-gas-lp/1068059603",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-1-servicio-blanco-g10-s/piloto-wifi-gas-natural/1068059611",
    },
    "g-15-sin-piloto-wifi": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-1-1/2-servicio-blanco-g15-s/piloto-wifi-gas-lp/1068062426",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-1-1/2-servicio-blanco-g-15-s/piloto-wi-fi-gas-natural/1068062434",
    },
    "g-20-sin-piloto-wifi": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-2-servicios-blanco-g20-s/piloto-wifi-gas-lp/1068062442",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-2-servicios-blanco-g20-s/piloto-wifi-gas-natural/1068062451",
    },
    "g-30-sin-piloto-wifi": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-103-litros-blanco-g30-sin-piloto-wifi-gas-lp/1068062469",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-calorex-103-litros-blanco-g30-s/piloto-wifi-gas-natural/1068062477",
    },
    
    "coxdp-06": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-1-servicio-blanco/1052727126?typeahead=yes",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-natural-calorex-1-servicio-blanco/1052727053?typeahead=yes",
    },
    "coxdp-09": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-a-gas-lp-calorex-9-litros-blanco-coxdp-09/1049090842",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-a-gas-natural-calorex-9-litros-blanco-coxdp-09/1049090851",
    },
    "coxdp-11": {},
    "coxdp-15": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-3-servicios-blanco-coxdp-15/1049090915",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-natural-calorex-15-litros-blanco-coxdp-15/1049090869",
    },
    "coxdp-20": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-a-gas-lp-calorex-4-servicios-blanco-coxdp-20/1049090877",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-natural-calorex-20-litros-blanco-coxdp-20/1049090885",
    },
    
    "coxdpe-06": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-calorex-1-servicio-blanco-coxdpe-06-gen-3/1071944952",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-calorex-1-servicio-blanco-coxdpe-06-gen-3/1071944961",
    },
    "coxdpe-09": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-calorex-1-1/2-servicio-blanco-coxdpe-09-gen-3/1071944979",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-1-1/2-servicio-blanco-coxdpe-09-gen-3/1071944987",
    },
    "coxdpe-11": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-agua-calorex-2-servicios-blanco-coxdpe-11/1039964372",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-calorex-2-servicios-blanco-coxdpe-11-gen-3/1071945002",
    },
    
    "coxdpi-07-b": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-1-servicio-blanco-coxdpi-07/1049091032",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-instant%C3%A1neo-a-gas-natural-calorex-1-servicio-blanco-coxdpi-07/1049091041",
    },
    "coxdpi-11": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-1-1/2-blanco-coxdpi-11/1049090923",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-a-gas-natural-calorex-1-1/2-servicio-blanco-coxdpi-11/1049090931",
    },
    "coxdpi-13-b": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-2-serivicios-blanco-coxdpi-13/1049091059",
    },
    "coxdpi-14": {
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-natural-calorex-2-servicios-blanco-coxdpi-14/1049091091",
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-2-servicios-blanco-coxdpi-14/1049091083",
    },
    "coxdpi-16": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-lp-calorex-3-servicios-blanco-coxdpi-16/1049091105",
    },
    "coxdpi-16-confort-max": {},
    "coxdpi-24": {
      lp: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-calorex-4-servicios-blanco-coxdpi-24/1049091121",
      natural: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-paso-a-gas-natural-calorex-4-servicios-blanco-coxdpi-24/1049091130",
    },
    "coxdpi-30": {},

    "e-10": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-1-servicio-blanco-e10/1049090966",
    },
    "e-15": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-1-1/2-servicios-blanco-e-15/1049090982",
    },
    // "e-15": {
    //   electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-1-1/2-servicios-blanco-e-15/1049090974",
    // },
    "e-20": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-2-servicios-blanco-e-20/1049090991",
    },
    "e-30": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-3-servicios-blanco-e-30/1049091008",
    },
    "e-40": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-el%C3%A9ctrico-calorex-4-servicios-blanco-e-40/1049091016",
    },
    "e-60": {},

    "pro-40-l": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-1-servicio-blanco-pro-40-l/1096763596",
    },
    "pro-50-l": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-1-1/2-servicio-blanco-pro-50-l/1096763600",
    },

    "levittas-45": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-1-1/2-servicio-blanco-l%C3%A9vittas-45/1096763618",
    },
    "levittas-65": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-2-servicios-blanco-l%C3%A9vittas-65/1096763626",
    },

    "eleva-4": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-instant%C3%A1neo-el%C3%A9ctrico-calorex-1/2-servicio-blanco-eleva/1096763642",
    },
    "eleva-9.5": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-instant%C3%A1neo-el%C3%A9ctrico-calorex-1-servicio-blanco-eleva/1096763651",
    },
    "eleva-12": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-instant%C3%A1neo-el%C3%A9ctrico-calorex-1-1/2-servicio-blanco-eleva/1096763669",
    },
    "eleva-pro-15": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-instant%C3%A1neo-el%C3%A9ctrico-calorex-2-servicios-negro-eleva-pro-15/1096778119",
    },

    "levare-30": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-1-servicio-blanco-levare-30-l/1096763634",
    },
    "levare-15": {
      electric: "https://www.liverpool.com.mx/tienda/pdp/calentador-de-dep%C3%B3sito-el%C3%A9ctrico-calorex-1/2-servicio-blanco-levare-15-l/1096763570",
    },

    "calorex-solar-sl-150": {
      sun: "https://www.liverpool.com.mx/tienda/pdp/calentador-solar-calorex-150-litros-gris-termosif%C3%B3n/1023454374",
    },
    "calorex-solar-sl-240": {
      sun: "https://www.liverpool.com.mx/tienda/pdp/calentador-solar-calorex-240-litros-gris-termosif%C3%B3n/1023454382",
    },
  }
  return links
}
