import React,{useState,useEffect} from 'react'
import Slider from 'rc-slider';

function SliderPeople( props ) {
    const [value, setValue] = useState(1)
    // eslint-disable-next-line
    const [min, setMin] = useState(props.min)
    // eslint-disable-next-line
    const [max, setMax] = useState(props.max)
    // eslint-disable-next-line
    const [step, setStep] = useState(1)
    const [caption,setCaption] = useState('persona')


    const marks = {
        1.0 : <span className='line__color'></span>,
        2.0 : <span className='line__color'></span>,
        3.0 : <span className='line__color'></span>,
        4.0 : <span className='line__color'></span>,
        5.0 : <span className='line__color'></span>,
        6.0 : <span className='line__color'></span>,
    };

    function modifyValue(value){
        props.setValue(value);
    }


    useEffect(() => {
        modifyValue(value)

        if(value===1)
        {
            setCaption('persona');
        }
        else{
            setCaption('personas');
        }
    // eslint-disable-next-line
    }, [value])



    return (
        <div className='option__slider--content'>
            <div className="slider__header--title">
                <h1>¿Cuántas personas van a disfrutar del agua caliente?</h1>
            </div>
            <div className='slider__container' >
                <div className="slider__selection">
                    <span>{ value } {caption} </span>
                </div>
                <Slider value={value} marks={marks} min={min} max={max} step={step} onChange={setValue} />

            </div>
        </div>
    )
}

export default SliderPeople