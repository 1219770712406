import React, { useState, useEffect, useRef } from "react"
import Slider from "react-slick"

import { usePrismVersionResult } from "../../hooks/use_prism_versions-results"
import { useModelsMatrix } from "../../hooks/use_models_matrix"
import { useLinkLabels } from "../../hooks/use_link_labels"

import CssClassManager from "../../components/_helpers/_css_class_manager"
import linksManager from "./_helpers/LinksManager"

import { TextGradient } from "../_text/_text_gradient"
import { ButtonArrow, TYPES } from "../../components/buttons/_btn_arrow"
import Check from "../svg/commons/check"
import ResultHeader from "./_result/result_header"
import PhoneRingning from "../svg/commons/phone_ringing"

function Result(props) {
  const [versions, setVersions] = useState([])
  const scrollButton = useRef(null)

  const resultQuery = usePrismVersionResult()
  const resultMatrix = useModelsMatrix()
  const linksLabels = useLinkLabels()
  const cssClassManager = new CssClassManager()

  let is_listenning = false

  useEffect(() => {
    // console.log("resultQuery ", resultQuery)
    let versions_by_uid = {}

    //versions indexed by uid
    for (let i = 0; i < resultQuery.edges.length; i++) {
      let version = resultQuery.edges[i]
      versions_by_uid[version.node.uid] = version
    }

    // console.log('hometype',props.hometype)
    // console.log('technologyType',props.technologyType)
    // console.log('services',props.services)
    // console.log('people',props.people)
    // console.log('res',resultMatrix[props.technologyType]);
    // console.log('res',resultMatrix[props.technologyType]['services'][props.services]);
    // console.log('res',resultMatrix[props.technologyType]['services'][props.services]['persons'][props.people]);
    let id_versions = resultMatrix[props.technologyType]["services"][props.services]["persons"][props.people]
    let versions_array = []

    id_versions.map(_version_id => {
      // console.log('_version_id',_version_id);
      versions_array.push(versions_by_uid[_version_id])
      return null
    })

    setVersions(versions_array)
    setScrollListenner()
    // eslint-disable-next-line
  }, [])

  function setScrollListenner() {
    is_listenning = true
    window.addEventListener("scroll", () => {
      if (scrollButton.current === null) return
      if (!is_listenning) return
      let lastScrollY = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop

      if (lastScrollY > 100) {
        cssClassManager.add_class(scrollButton.current, "is-hidden")

        is_listenning = false
      }
    })
  }

  function get_cover(_version, _key) {
    return (
      <div key={`result_body--cover${_key}`} className="result_body--cover">
        <div className="result__image">
          <img src={_version.node.data.image.url} alt={_version.node.data.image.alt}></img>
          {/* <Picture
            sources={[
              {
                srcSet: _version.node.data.result_image.mb.url,
                media: "(max-width: 768px)",
              },
              {
                srcSet: _version.node.data.result_image.tb.url,
                media: "(max-width: 1366px)",
              },
              {
                srcSet: _version.node.data.result_image.lg.url,
                media: "(min-width: 1919px)",
              },
              {
                srcSet: _version.node.data.result_image.url,
                media: "(min-width: 1919px)",
              },
            ]}
          /> */}
        </div>
      </div>
    )
  }

  function get_copy(_version, _key) {
    return (
      <div key={`result_body--copy${_key}`} className="result_body--copy">
        <div className="result_body--copycontainer">
          <p className="result_body--copy_title">{_version.node.data.model.document[0].data.claim.text}</p>
          <p className="result_body--copy_description">{_version.node.data.model.document[0].data.ideal_copy.text}</p>
        </div>
      </div>
    )
  }

  function get_uses(_version, _key) {
    return (
      <div key={`result_body--uses${_key}`} className="result_body--uses">
        <div className="result__ideals">
          <div className="check__icon">
            <Check stroke="#000" />
          </div>
          <h2>Usos ideales</h2>
          <ul className="ideals__table">
            <li className="ideal__text"> {_version.node.data.model.document[0].data.ideal_list.text} </li>
          </ul>
        </div>
      </div>
    )
  }

  function get_advantages(_version, _key) {
    return (
      <div key={`result_body--advantages${_key}`} className="result_body_section result_body--uses">
        <div className="result__advantages">
          <div className="check__icon">
            <Check stroke="#000" />
          </div>
          <h2>Ventajas</h2>
          <ul className="advantages__table">
            {
              // eslint-disable-next-line
              _version.node.data.model.document[0].data.body.map(element => {
                if (element.__typename === "PrismicModelBodyVentajasResultado") {
                  return element.items.map((item, _key) => {
                    return <li key={`advantage-${_key}`} className="advantage__text" dangerouslySetInnerHTML={{ __html: item.advantage.html }} />
                  })
                }
              })
            }
          </ul>
        </div>
      </div>
    )
  }

  function getMobileTemplate() {
    const settings = {
      customPaging: function (i) {
        return <span className="pagination__bullet"></span>
      },
      dots: true,
      arrows: false,
      dotsClass: "pagination__dots",
      lazyLoad: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,

      nextArrow: <div className={`banner__prev cursor__change`}></div>,
      prevArrow: <div className={`banner__next cursor__change`}></div>,
    }

    return versions.map((_version, _key) => {
      const links = linksManager.links[_version.node.uid]

      return (
        <div key={`result_body_item${_key}`} className="result_body_item">
          <div className="result_body_header">
            <div className="result_body_header_container">
              <TextGradient _text={_version.node.data.model.document[0].data.model_name.text} _className="result__header__model" _color_start="#ba0c2f" _color_end="#d90b2d" _deg={180} />
              <p className="result__version">{_version.node.data.title.text}</p>
            </div>
          </div>
          <Slider key={`result_slider_${_key}`} {...settings}>
            {[get_cover(_version, _key), get_copy(_version, _key), get_uses(_version, _key), get_advantages(_version, _key)]}
          </Slider>
          {/* {links && (
            <div className="result__links--container">
              {Object.keys(links).map((link, index) => {
                return <ButtonArrow key={`result-link-${link}-${index}`} _className="result__modelo__btn" _section="result_modelo" _text={`${linksLabels[link]}`} _href={`${links[link]}`} _type={TYPES.NEXT}></ButtonArrow>
              })}
            </div>
          )} */}
        </div>
      )
    })
  }

  function get_scroll_btn() {
    return (
      <div className="result__scroll__btncontainer" ref={scrollButton}>
        <ButtonArrow _className="result__scroll__btn" _section="result_scroll" _onClick={props._backToHelp} _text="Scroll" _position="after" _type={TYPES.DOWN}></ButtonArrow>
      </div>
    )
  }

  function getDesktopTemplate() {
    return versions.map((_version, _key) => {
      const links = linksManager.links[_version.node.uid]

      return (
        <div key={`result_body_item${_key}`} className="result_body_item">
          <div className="result_body_section_container">
            <div className="result_body_left result_body_section">{get_cover(_version, _key)}</div>
            <div className="result_body_center result_body_section">
              <div className="result_body_header">
                <div className="result_body_header_container">
                  <TextGradient _text={_version.node.data.model.document[0].data.model_name.text} _className="result__header__model" _color_start="#ba0c2f" _color_end="#d90b2d" _deg={180} />
                  <p className="result__version">{_version.node.data.title.text}</p>
                </div>
              </div>
              {get_copy(_version, _key)}
              {/* {links && (
                <div className="result__links--container">
                  {Object.keys(links).map((link, index) => {
                    return <ButtonArrow key={`result-link-${link}-${index}`} _className="result__modelo__btn" _section="result_modelo" _text={`${linksLabels[link]}`} _href={`${links[link]}`} _type={TYPES.NEXT}></ButtonArrow>
                  })}
                </div>
              )} */}
            </div>
            <div className="result_body_right result_body_section">
              {get_uses(_version, _key)}
              {get_advantages(_version, _key)}
            </div>
          </div>
        </div>
      )
    })
  }

  if (resultQuery != null) {
    return (
      <div className="result__container">
        {window.innerWidth >= 1024 && versions.length > 1 ? get_scroll_btn() : ""}
        <div className="result__back">
          <ButtonArrow _className="result__back__btn" _section="result_header" _onClick={props._backToHelp} _text="Iniciar" _position="before" _type={TYPES.PREV}></ButtonArrow>
        </div>
        <ResultHeader slug="Slug" name="Name" title="title" colorStart={"#ba0c2f"} colorEnd={"#d90b2d"} />
        <div className="result__body">{window.innerWidth >= 1024 ? getDesktopTemplate() : getMobileTemplate()}</div>
        <div className="result__footer">
          <div className="result__footer_container">
            <div className="detail_maintenance_header_telcontainer">
              <div className="detail_maintenance_header_telicon">
                <PhoneRingning></PhoneRingning>
              </div>
              <div className="detail_maintenance_header_info_container">
                <div className="detail_maintenance_header_info_llamanos">Llámanos:</div>
                <div className="detail_maintenance_header_info_tel">800 CALOREX (225 67 39) </div>
              </div>
            </div>
            <div className="result_footer_claim">
              Considera que los resultados que se proponen son basados en información básica de tu requerimiento, y pueden variar de acuerdo a condiciones especiales (tamaño del domicilio, distancia entre calentador y regaderas, uso de servicios simultáneos, duración de la ducha, presión de agua, llaves monomando de agua, etc). Si requieres de mayor asesoría contáctanos mediante el chat en línea.
              <br />
              *Para duchas continuas en calentadores de Depósito, se está considerando un tiempo promedio de recuperación de 15 minutos entre ducha y ducha.
              <br />
              **Para modelos Paso Todo en Uno, si la distancia entre el calentador y la regadera(s) es mayor a 10 m2, tomar el modelo siguiente al recomendado.
              <br />
              ***Para modelos Instantáneos, si la distancia entre el calentador y la regadera(s) es mayor a 7 m2, tomar el modelo siguiente al recomendado. Busca asesoría adicional si utilizas llaves de agua monomando.
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Result
